<template>
  <div>
    <div class="lcf-home">
      <Swipe
        class="lcf-home-swipe"
        :autoplay="3000"
        ref="swipe"
        v-if="banners.length"
      >
        <SwipeItem v-for="(v, index) in banners" :key="index">
          <VImage
            v-show="v.img"
            :src="v.img"
            :alt="v.title"
            width="100%"
            height="100%"
            @click="linkTo(bannersMap[v.url] || '')"
          />
        </SwipeItem>
      </Swipe>

      <!-- <div class="lcf-home-grid">
        <div class="lcf-xxpl" @click="go('InfoDisNav')" v-waves>
          <div></div>
          <div>信息披露</div>
        </div>

        <div class="van-hairline--left"></div>

        <div class="lcf-fkcs" @click="go('RiskControlMeasures')" v-waves>
          <div></div>
          <div>风控措施</div>
        </div>
      </div> -->

      <div class="lcf-home-notice">
        <div>
          <Icon name="notes-o"></Icon>
          <span>平台公告</span>
        </div>
        <!-- <Icon name="bullhorn-o"></Icon> -->
        <div @click="go('NoticeList')" v-waves>
          <span>查看全部</span>
          <Icon name="arrow"></Icon>
        </div>
      </div>

      <ListTransition>
        <div
          v-for="(v, k) in notices"
          :key="k"
          class="lcf-home-notice-list-item van-hairline--top van-hairline--top"
          @click="go('NoticeDetail', { params: { id: v.id } })"
          :data-index="k"
          :data-page-size="10000"
          v-waves
        >
          <div class="lcf-home-notice-item-head">
            <div class="van-ellipsis">
              <Icon name="new"></Icon>
              {{ v.title }}
            </div>
            <div>
              <Icon name="clock-o"></Icon>
              {{ time(v.addtime) }}
            </div>
          </div>
          <div class="lcf-home-notice-item-body van-multi-ellipsis--l2">
            {{ v.instr }}
          </div>
        </div>
      </ListTransition>

      <!-- <div class="lcf-home-operation">
        <div class="lcf-home-operation-head">合规运营数据</div>
        <div class="lcf-home-operation-body">
          <div class="lcf-home-operation-body-p1">
            <div></div>
            <div>{{ guideData.online_time }}</div>
            <div>安全运营（天）</div>
          </div>
          <div class="lcf-home-operation-body-p2">
            <div></div>
            <div>{{ guideData.total_amounts }}</div>
            <div>累计交易（亿元）</div>
          </div>
          <div class="lcf-home-operation-body-p3">
            <div></div>
            <div>{{ guideData.total_users }}</div>
            <div>累计注册（万人）</div>
          </div>
        </div>
      </div>

      <div class="lcf-home-about-us" v-waves @click="go('AboutUs')">
        <div></div>
      </div> -->

      <div class="lcf-common-bottom-tip">市场有风险，出借需谨慎</div>
    </div>
  </div>
</template>

<script>
import { Notify, Swipe, SwipeItem, Icon, Image } from 'vant'
import api from '../api'
import { mapActions } from 'vuex'

export default {
  components: { Swipe, SwipeItem, Icon, VImage: Image },
  data() {
    return {
      banners: [{ img: '', title: '' }],
      notices: [],

      guideData: {
        online_time: '-',
        total_amounts: '-',
        total_users: '-',
      },

      bannersMap: {
        user_rights_confirm: '/notice/1900',
        jieyou_mall: '//licaifan.com/newmall',
        mall: '//mall.licaifan.com',
      },
    }
  },
  created() {
    this.requestSwipeData()
    this.requestNoticeData()
    this.showGuideData()
  },
  methods: {
    ...mapActions(['requestGuideData']),
    go(routeName, p) {
      setTimeout(() => {
        this.$router.push({ name: routeName, ...p })
      }, 300)
    },
    /**
     * 获取Banner
     *  'index_bbs'=>'首页-社区',
     *  'discovery_bbs'=>'发现-社区',
     *  'app_exchange_banner'=>'APP产品详情横幅',
     *  'pc_exchange_banner'=>'PC产品详情横幅',
     *  'index_banner_2'=>'首页横幅(二)',
     *  'index_banner_1'=>'首页横幅(一)',
     *  'app_rolling'=>'App滚动公告',
     *  'app_gonggao'=>'App公告图片',
     *  'app_discovery'=>'App发现轮播图',
     *  'fanhot_adv'=>'范泛而谈',
     *  'index_adv'=>'首页推荐',
     *  'jifen_mall_image' => '积分商城banner',
     *  'app_load_image' => 'APP启动画面',
     *  'ios_android' => 'APP公共轮播图',
     *  'android' => 'Android轮播图',
     *  'ios' => 'IOS轮播图',
     *  '5' => '登录页广告',
     *  '4' => '自媒体轮播图',
     *  '3' => '友情链接',
     *  '2' => '合作伙伴',
     *  '1' => '网站轮播图'
     * @returns
     */
    async requestSwipeData() {
      let banners = []
      let error, response
      ;[error, response] = await to(
        api.general.banner({
          position: 'ios_android',
        })
      )

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      }

      if (response?.data?.code === -1) {
        banners = response.data.banners
        this.banners = banners
      }
      return banners
    },
    async requestNoticeData() {
      let notices = []
      let error, response
      ;[error, response] = await to(api.general.notice('notice'))

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      }

      if (response?.data?.code === -1) {
        notices = response.data.notices
        this.notices = notices
      }

      return notices
    },

    async showGuideData() {
      let error, data
      ;[error, data] = await to(this.requestGuideData())

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      }

      if (data) {
        this.guideData.online_time = data.online_time
        this.guideData.total_amounts =
          Math.round((data.total_amounts / 100000000) * 100) / 100
        this.guideData.total_users =
          Math.round((data.total_users / 10000) * 100) / 100
      }
      return data
    },
    linkTo(url) {
      location.href = url
    },
    noticeClick(e) {
      this.$router.push({
        name: 'NoticeList',
      })
    },
    noticeSwipeClick(e, v) {
      e.stopPropagation()
      e.cancelBubble = true

      this.$router.push({
        name: 'NoticeDetail',
        params: { id: v && v.id },
      })
    },
  },
  computed: {
    time() {
      return (time) => {
        let ret = ''
        try {
          let date = new Date(time)
          let year = date.getFullYear(),
            month = date.getMonth() + 1,
            day = date.getDate()
          if ([year, month, day].some((x) => isNaN(x))) {
            throw ''
          }

          ret =
            `${year}-` +
            `${month}`.padStart(2, 0) +
            '-' +
            `${day}`.padStart(2, 0)
        } catch (e) {
          //
        }
        return ret
      }
    },
  },
}
</script>

<style scoped lang="scss">
.lcf-home {
  background: #fff; //$lcf-color-bg-gray-light;
  padding: 0 0 1px;
  .lcf-home-swipe {
    //
    min-height: 210px;
  }
  .lcf-home-grid {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transform: translateY(-8px);
    border-radius: 8px;
    margin: 0 16px;
    box-shadow: 0 0 8px -1px rgba($color: #000000, $alpha: 0.2);
    background: #fff;
    .van-hairline--left {
      height: 32px;
    }
    .lcf-xxpl,
    .lcf-fkcs {
      border-radius: 8px;
      padding: 16px 0;
      flex: 1 1;
      display: flex;
      flex-flow: column nowrap;
      text-align: center;
      height: 80px;
      width: 100%;
      font-size: 12px;
      line-height: 12px;
    }
    .lcf-xxpl {
      & > div:nth-child(1) {
        flex: 3 1 80px;
        background: content-box center / 80px auto no-repeat
          url('~@/assets/img/icon_home_information_disclosure.png');
      }
      & > div:nth-child(2) {
        flex: 1 1;
      }
    }
    .lcf-fkcs {
      & > div:nth-child(1) {
        flex: 3 1 80px;
        background: content-box center / 80px auto no-repeat
          url('~@/assets/img/icon_home_safety_guarantee.png');
      }
      & > div:nth-child(2) {
        flex: 1 1;
      }
    }
  }
  .lcf-home-notice {
    // background: $lcf-color-red-light;
    // margin: 0 auto 8px;
    // width: 96px;
    height: 48px;
    // padding: 1px 8px;
    // border-radius: 8px;
    padding: 0 16px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    font-size: 16px;
    color: $lcf-color-default;
    // & > i {
    //   flex: 2 0;
    // }
    // & > span {
    //   flex: 7 0;
    // }
    & > div:nth-child(1) {
      flex: 1 1;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      & > i:nth-child(1) {
        font-size: 24px;
        margin-right: 4px;
      }
    }
    & > div:nth-child(2) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 0 1 64px;
      border-radius: 2px;
      height: 100%;
      font-size: 12px;
    }
    // & > i:nth-child(1) {
    //   font-size: 18px;
    // }
    // & > span:nth-child(2) {
    //   font-size: 16px;
    // }
    // & > span:nth-child(3) {
    //   font-size: 12px;
    // }
    // & > i:nth-child(4) {
    //   font-size: 12px;
    // }
  }
  .lcf-home-notice-list-item {
    background: #fff;
    margin: 0 16px 12px;
    padding: 8px 16px 8px;
    // padding: 0 0 8px;
    border-radius: 4px;
    box-shadow: 0 0 8px -1px rgba($color: #000000, $alpha: 0.2);
    .lcf-home-notice-item-head {
      display: flex;
      flex-flow: row nowrap;
      font-size: 12px;
      margin: 0 0 8px;
      height: 24px;
      line-height: 24px;
      align-items: center;
      div:nth-child(1) {
        flex: 2 1;
        color: $lcf-color-default;
        font-size: 12px;
        i {
          font-size: 18px;
          transform: translateY(4px);
        }
      }
      div:nth-child(2) {
        // i {
        //   color: $lcf-color-default;
        // }
        flex: 0 0 92px;
        text-align: right;
        color: $lcf-color-text-gray;
      }
    }
    .lcf-home-notice-item-body {
      font-size: 12px;
      line-height: 24px;
      color: $lcf-color-text-gray;
    }
  }
  .lcf-home-operation {
    margin: 0 16px 8px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 8px -1px rgba($color: #000000, $alpha: 0.2);
    padding: 16px;
    .lcf-home-operation-head {
      text-align: center;
      font-size: 12px;
    }
    .lcf-home-operation-body {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      padding: 16px 0 0;
      .lcf-home-operation-body-p1,
      .lcf-home-operation-body-p2,
      .lcf-home-operation-body-p3 {
        text-align: center;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        height: 96px;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        & > div:nth-child(1) {
          flex: 1 1;
          width: 32px;
        }
        & > div:nth-child(2) {
          flex: 1 1;
          font-size: 18px;
        }
        & > div:nth-child(3) {
          flex: 1 1;
          font-size: 12px;
          color: $lcf-color-text-gray;
        }
      }
      .lcf-home-operation-body-p1 > div:nth-child(1) {
        background: border-box center / 32px auto no-repeat
          url('~@/assets/img/icon_home_safe_operation.png');
      }
      .lcf-home-operation-body-p2 > div:nth-child(1) {
        background: border-box center / 32px auto no-repeat
          url('~@/assets/img/icon_home_sum_recharge.png');
      }
      .lcf-home-operation-body-p3 > div:nth-child(1) {
        background: border-box center / 32px auto no-repeat
          url('~@/assets/img/icon_home_sum_register.png');
      }
    }
  }
  .lcf-home-about-us {
    margin: 0 16px 8px;
    height: 64px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 8px -1px rgba($color: #000000, $alpha: 0.2);
    & > div {
      width: 144px;
      height: 100%;
      margin: 0 auto;
      background: border-box 0% 50% / auto 48px no-repeat
          url('~@/assets/img/ic_about_us_logo.png'),
        border-box 100% 50% / auto 16px no-repeat
          url('~@/assets/img/ic_about_us_title.png');
    }
  }
}
</style>
